// Externe styles müssen wegen der Unit-Tests als Default exportiert werden
// https://github.com/zeit/styled-jsx/issues/436
import appStyles from 'config/appStyles'

import Router from 'next/router'
import App from 'next/app'
import { captureException, init, withScope } from '@sentry/node'
import CookieConsent from 'config/components/CookieConsent'
import Spinner from 'shared/components/Spinner'
import 'normalize.css'

// Sentry-Integration:
// - https://leerob.io/blog/configuring-sentry-for-nextjs-apps
// - https://github.com/zeit/next.js/tree/master/examples/with-sentry-simple/pages
init({ dsn: process.env.KIMETA_SENTRY_DSN })

class KimetaApp extends App {
	constructor(props) {
		super(props)
		this.state = {}
		this.setIsLoading = this.setIsLoading.bind(this)
		this.setIsNotLoading = this.setIsNotLoading.bind(this)
	}

	static async getInitialProps({ Component, ctx }) {
		let pageProps = {}

		if (Component.getInitialProps) {
			try {
				pageProps = await Component.getInitialProps(ctx)
			} catch (err) {
				// Fehler werden erstmal nur serverseitig konkret gecaptured
				// (clientseitig geschieht dies allgemein)
				if (ctx.req) {
					withScope(scope => {
						scope.setExtra('url', ctx.asPath)
						scope.setTag('ssr', 'yes')
						scope.setTag('getInitialProps', 'yes')
						captureException(err)
					})
				}

				throw err
			}
		}

		return { pageProps }
	}

	componentDidMount() {
		window.history.scrollRestoration = 'auto'
		const cachedScrollPositions = []
		let shouldScrollRestore

		Router.events.on('routeChangeStart', () => {
			cachedScrollPositions.push([window.scrollX, window.scrollY])
		})

		Router.events.on('routeChangeComplete', () => {
			if (shouldScrollRestore) {
				const { x, y } = shouldScrollRestore
				window.scrollTo(x, y)
				shouldScrollRestore = null
			}
			window.history.scrollRestoration = 'auto'
		})

		Router.beforePopState(() => {
			if (cachedScrollPositions.length > 0) {
				const [x, y] = cachedScrollPositions.pop()
				shouldScrollRestore = { x, y }
			}
			window.history.scrollRestoration = 'manual'
			return true
		})

		Router.events.on('routeChangeError', this.setIsNotLoading)
		document.addEventListener('hideSpinnerOverlay', this.setIsNotLoading)
		document.addEventListener('showSpinnerOverlay', this.setIsLoading)
	}

	componentWillUnmount() {
		document.removeEventListener('hideSpinnerOverlay', this.setIsNotLoading)
		document.removeEventListener('showSpinnerOverlay', this.setIsLoading)
	}

	render() {
		const { Component, pageProps } = this.props

		return (
			<>
				<div className={`overlay ${this.state.isLoading ? '' : 'invisible'}`}>
					<div className="spinner">
						<Spinner />
					</div>
				</div>
				<CookieConsent />
				<div className="content-outer">
					<Component {...pageProps} />
				</div>
				<style jsx global>{`
					address,
					article,
					aside,
					audio,
					blockquote,
					canvas,
					del,
					div,
					dl,
					fieldset,
					figcaption,
					figure,
					footer,
					form,
					h1,
					h2,
					h3,
					h4,
					h5,
					h6,
					header,
					hgroup,
					li,
					ol,
					p,
					pre,
					section,
					table,
					td,
					tfoot,
					th,
					ul,
					video {
						box-sizing: border-box;
					}

					body,
					figure,
					h1,
					h2,
					h3,
					h4,
					h5,
					h6,
					html,
					input,
					li,
					ul {
						margin: 0;
						padding: 0;
					}
				`}</style>
				<style jsx global>
					{appStyles}
				</style>
				<style jsx>{`
					.overlay.invisible {
						visibility: 0;
						opacity: 0;
						pointer-events: none;
						transition: all 0.1s;
					}
					.overlay {
						transition: all 0.3s 0.9s;
						visibility: 1;
						opacity: 1;
						position: fixed;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba(0, 0, 0, 30%);
						z-index: 1000;
					}
					.spinner {
						position: absolute;
						width: 200px;
						height: 200px;
						top: 50%;
						left: calc(50% + 20px);
						transform: translate(-50%, -50%);
					}

					@media print {
						.content-outer {
							padding-top: 0px;
						}
					}
				`}</style>
			</>
		)
	}

	setIsLoading() {
		this.setState({ isLoading: true })
	}

	setIsNotLoading() {
		this.setState({ isLoading: false })
	}
}

export default KimetaApp
