import { accentColor } from 'config/style'
const Spinner = () => (
	<div className="outer">
		<div className="inner" />
		<style jsx>{`
			@keyframes rotate {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}

			.outer {
				height: 200px !important;
				position: relative;
				transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
				width: 200px !important;
			}

			.inner {
				animation: rotate 1s linear infinite;
				border-radius: 50%;
				box-shadow: 0 6px 0 0 ${accentColor};
				height: 160px;
				left: 20px;
				top: 20px;
				transform-origin: 80px 83px;
				width: 160px;
			}
		`}</style>
	</div>
)

export default Spinner
