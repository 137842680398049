import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

const consentCookieKey = 'CookieConsent'

const CookieConsent: React.FC = () => {
	const [showConsent, setShowConsent] = useState(false)

	useEffect(() => {
		const cookieConsent = Cookies.get(consentCookieKey)
		setShowConsent(cookieConsent === undefined)
	}, [])

	if (!showConsent) {
		return null
	}

	const hideCookieConsent = () => {
		Cookies.set(consentCookieKey, 'true', { expires: 365 })
		setShowConsent(false)
	}

	return (
		<>
			<div className="cookie-consent">
				<p>
					Cookies erleichtern die Bereitstellung unserer Dienste. Mit der
					Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass
					wir Cookies verwenden.
				</p>
				<button onClick={hideCookieConsent}>OK</button>
			</div>
			<style jsx>{`
				button {
					color: white;
					background-color: #aacc00;
					border: none;
					font-weight: 500;
					padding: 4px 5px 5px;
					margin-top: 14px;
					cursor: pointer;
					flex: 0 0 auto;
					max-height: 25px;
				}
				.cookie-consent {
					font-size: 14px;
					position: inherit;
					top: 0;
					color: white;
					background-color: #2b373b;
					width: 100%;
					padding: 1px 15px;
					display: flex;
					z-index: 5;
				}

				p {
					flex: 1 0 200px;
					line-height: 24px;
				}

				:global(.cookie-consent + .content-outer) {
					padding-top: 0px;
				}

				@media (max-width: 768px) {
					.cookie-consent {
						margin-top: 60px;
					}
				}
				@media print {
					.cookie-consent {
						display: none;
					}
				}
			`}</style>
		</>
	)
}

export default CookieConsent
