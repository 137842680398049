import css from 'styled-jsx/css'

const appStyles = css.global`
	body {
		background: #f4f3f3;
		color: #292926;
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
			Helvetica Neue, Arial, sans-serif;
	}

	@media print {
		body {
			background: white;
		}
	}
`

export default appStyles
